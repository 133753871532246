// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/home","parentId":"ant-design-pro-layout","id":"1"},"2":{"name":"登陆","path":"/login","layout":false,"id":"2"},"3":{"name":"监控台","path":"/home","icon":"HomeOutlined","parentId":"ant-design-pro-layout","id":"3"},"4":{"name":"用户管理","path":"/user","icon":"TeamOutlined","access":"canAdmin","parentId":"ant-design-pro-layout","id":"4"},"5":{"name":"数据管理","path":"/log","icon":"HddOutlined","access":"canCheckApi","parentId":"ant-design-pro-layout","id":"5"},"6":{"name":"系统日志","path":"/log/system","access":"canAdmin","parentId":"5","id":"6"},"7":{"name":"短信记录","path":"/log/sms","access":"canCheckApi","parentId":"5","id":"7"},"8":{"name":"校验记录","path":"/log/survey","access":"canCheckApi","parentId":"5","id":"8"},"9":{"name":"报告记录","path":"/log/report","access":"canCheckApi","parentId":"5","id":"9"},"10":{"name":"短链接记录","path":"/log/short","access":"canAdmin","parentId":"5","id":"10"},"11":{"name":"短链访问记录","path":"/log/shortLog","access":"canAdmin","parentId":"5","id":"11"},"12":{"name":"短信上行记录","path":"/log/smsReply","access":"canAdmin","parentId":"5","id":"12"},"13":{"name":"可视化请求","path":"/check","icon":"HddOutlined","access":"canAdmin","parentId":"ant-design-pro-layout","id":"13"},"14":{"name":"短信请求","path":"/check/sms","access":"canAdmin","parentId":"13","id":"14"},"15":{"name":"接口请求","path":"/check/survey","access":"canAdmin","parentId":"13","id":"15"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'@/pages/Login/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'@/pages/Home/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__User__index" */'@/pages/User/index.tsx')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__log__System__index" */'@/pages/log/System/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__log__Sms__index" */'@/pages/log/Sms/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__log__Survey__index" */'@/pages/log/Survey/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__log__Report__index" */'@/pages/log/Report/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__log__Short__index" */'@/pages/log/Short/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__log__ShortLog__index" */'@/pages/log/ShortLog/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__log__SmsReply__index" */'@/pages/log/SmsReply/index.tsx')),
'13': React.lazy(() => import('./EmptyRoute')),
'14': React.lazy(() => import(/* webpackChunkName: "p__Check__Sms__index" */'@/pages/Check/Sms/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__Check__Survey__index" */'@/pages/Check/Survey/index.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/opt/base_service_admin/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
